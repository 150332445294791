import { fetchWithToken } from 'utils/fetch';
import { mapDocumentsTree } from 'utils/mapping';
import { urlEncodeBody } from 'utils/requests';

export async function getFoldersWithDocuments(): Promise<AnyNode[]> {
  const response = await fetchWithToken('/documents');
  const data = await response.json();

  return mapDocumentsTree(data);
}

export async function getFormDataUser(): Promise<AnyNode[]> {
  const response = await fetchWithToken('/users/user_info');
  const data = await response.json();

  return data;
}

export async function createFolder(values: { name: string; parentId?: string }): Promise<void> {
  await fetchWithToken('/documents/folder', {
    method: 'POST',
    body: urlEncodeBody({ folder: values.name, parent_id: values.parentId })
  });
}
